const testimonials = [
  {
    author: "Tammy C.",
    location: "Missouri",
    description: `I just wanted to drop you a line and let you know that we used this list to do an Outreach last Sunday October 12th. We
      sent out ten small teams armed with the detailed information you provided, we were able to devise/carry out a non-invasive
      personal outreach in our community and it went very well. We touched 100 families in less than 2 hours and we believe it
      stimulated much interest in the church, our event offerings, and helped us get our name out to the new people coming into
      the community. Thank you for helping us work to plant seeds for Jesus Christ in the North Jefferson County region of
      Missouri in this way! We pray and are believing for awesome results.`,
  },
  {
    author: "Sara S.",
    location: "North Carolina",
    description: `We have been so thankful for Doug and his team at New Movers Evangelism. As a result of the current lists that we obtained,
    we hear story after story of people who have come to our Worship Celebrations through our direct mailing outreach. These
    folks are now in a position to hear the Gospel preached (some for the very first time!), become involved in a life group,
    and serve the Kingdom through their talents and time.`,
  },
  {
    author: "Leon M.",
    location: "New Hampshire",
    description: `I just wanted to let you know that your service has been a vital part of our outreach to the newcomers in our community. We
    have gained several new members over the past few months, and continue to find new ways to utilize all the information we
    receive from you. Thank you for being an important part of our ministry, and may the Lord richly bless you in yours!`,
  },
  {
    author: "Chris C.",
    location: "Wisconsin",
    description: `We operate welcome to newcomer services in Wisconsin and Michigan and have had the pleasure of working with Doug at New
    Movers Evangelism for over 20 years. He is a delight to work with and New Movers has enabled us to welcome countless
    families to their new communities. We would highly recommend Doug's New Movers service to anyone wishing to reach new
    residents.`,
  },
  {
    author: "Jesse L.",
    location: "Texas",
    description: `Thanks so much for this wonderful service you provide our church. We have had many visitors as a direct result of this
    resource you have made available. As our church is growing, we are growing into a position that allows us to be able to
    visit in a larger radius new move in families in our area.`,
  },
  {
    author: "Bruce W.",
    location: "Michigan",
    description: `New Movers Evangelism has been a real blessing to our congregation! Not only have we received a positive response
    "Yes, put us on your mailing list", from 8 out of every 100 addresses but more members of this congregation
    than ever before are involved in some type of ministry directly linked to evangelism. Forty six (46%) of our membership are
    involved in some facet of New Movers Evangelism.`,
  },
  {
    author: "Nathan S.",
    location: "Wisconsin",
    description: `Thanks. We really enjoy the service you provide and actually have about ten members over the years due to visits we've
    made from the names on the new mover's list.`,
  },
  {
    author: "Cindy R.",
    location: "Ohio",
    description: `Thank you for your ministry. The labels that you supply have been a blessing to our church. We have seen several visitors
    resulting in souls being saved and/or joining the church family at Mill Creek since we started over a year ago. It is a
    wonderful way for our "senior saints" to keep involved in a ministry, by praying over each label before sending
    them out. May God continue blessing you and your ministry.`,
  },
  {
    author: "Kay K.",
    location: "Pennsylvania",
    description: `I wanted to share with you that as we make follow-up calls to our letter/CD, we have had a few people to tell us they are
    still unpacking. Your numbers are very current! We plan to start something in February or March that is similar to welcome
    wagon. Our area does not have a welcome wagon and this will give us another contact with these families. Thank you so much
    for your part!`,
  },
  {
    author: "James S.",
    location: "Colorado",
    description: `Through your program we have had 60% of the invited show up for the word of God and from that we have a 23% retention.
    These are averages based on the past 5 months. Without your help we would not be reaching our community like we should be
    doing. Your service is doing justice for the kingdom of heaven with rewards that are out of this world. THANK YOU THANK YOU
    THANK YOU for your work that is so essential to church growth.`,
  },
  {
    author: "Randy D.",
    location: "Massachusetts",
    description: `I wanted to share with you what great results we have had by using your program. We thank God for this program and your
    ministry as you help us reach the lost for Christ.`,
  },
  {
    author: "Connie G.",
    location: "Ohio",
    description: `Thank you so much for your service to us. Doug is a pleasure to work with. We have used "New Movers" for
    several years and have contacted many, many people in our community through this service. Several of these people have come
    to our services, and we have been greatly blessed.`,
  },
  {
    author: "Jesse Latta",
    location: "Texas",
    description: `We have been using New Movers Evangelism since before I came on staff over 6 years ago. This is a tremendous resource
    for our church to be able to reach out to those families who are new move-ins to our area. Over the years, we have added
    multiple zip codes to provide more contacts for us. Every week, members of our church deliver a welcome bag that includes
    gifts and information about our church to those who have recently moved into a new home. There is no way to quantify how
    effective this ministry has been toward sharing the gospel and reaching families. As we go faithfully and use this method as
    well as door knocking and other avenues, hardly a week goes by that we do not see the Lord bless by sending new families to
    visit our church services. This is a service we would highly recommend to any church that has the desire to reach their
    community with the good news of Jesus Christ.`,
  },
]

export default testimonials
