import "../styles/fontawesome.css"
import "../styles/animated.css"
import "../styles/owl.css"
import "../styles/templatemo-space-dynamic.css"
import "../styles/custom.css"
import "bootstrap/dist/css/bootstrap.min.css"
import Seo from "../components/seo/seo"
import testimonials from "../components/data/testimonials"

import React, { useState } from "react"

export default function Home() {
  const [testimonialIndex, setTestimonialIndex] = useState(0)
  const currentTestimonial = testimonials[testimonialIndex]

  return (
    <>
      <Seo
        title="Targeted Mailing Lists for Your Area(s) | New Movers Evangelism"
        description="We provide New Mover Mailing Lists, Home Buyer Lists, Occupant Lists and Phone Connect Lists for new people in your selected area(s) or zip codes."
      />
      <header
        className="header-area header-sticky wow slideInDown"
        data-wow-duration="0.75s"
        data-wow-delay="0s"
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <nav className="main-nav">
                <a href="index.html" className="logo">
                  <h4>
                    New<span>Movers</span>Evangelism
                  </h4>
                </a>
                <ul className="nav">
                  <li className="scroll-to-section">
                    <a href="#top" className="active">
                      Home
                    </a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#services">Services</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#about">About Us</a>
                  </li>
                  <li className="scroll-to-section">
                    <a href="#contact">Message Us</a>
                  </li>
                  <li className="scroll-to-section">
                    <div className="main-red-button">
                      <a href="#contact">Free Quote</a>
                    </div>
                  </li>
                </ul>
                <a className="menu-trigger">
                  <span>Menu</span>
                </a>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <div
        className="main-banner wow fadeIn"
        id="top"
        data-wow-duration="1s"
        data-wow-delay="0.5s"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 align-self-center">
                  <div
                    className="left-content header-text wow fadeInLeft"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <h2>Grow your church membership</h2>
                    <p>
                      Our Targeted Mailing Lists help you connect with New
                      Movers in your area(s).
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="right-image wow fadeInRight"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <img
                      src="assets/images/banner-right-image.png"
                      alt="team meeting"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="services" className="about-us section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div
                className="left-image wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <img
                  src="assets/images/about-left-image.png"
                  alt="person graphic"
                />
              </div>
            </div>
            <div className="col-lg-8 align-self-center">
              <div className="services">
                <div className="row">
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.5s"
                    >
                      <div className="icon">
                        <img
                          src="assets/images/service-icon-01.png"
                          alt="reporting"
                        />
                      </div>
                      <div className="right-text">
                        <h4>New Mover Mailing Lists</h4>
                        <p>
                          Our most popular lists, the New Mover Mailing Lists
                          provide names and addresses for new movers in your
                          areas.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.7s"
                    >
                      <div className="icon">
                        <img src="assets/images/service-icon-02.png" alt="" />
                      </div>
                      <div className="right-text">
                        <h4>Home Buyer Lists</h4>
                        <p>
                          The Home Buyer List is a list of home purchases taken
                          from courthouse records.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="0.9s"
                    >
                      <div className="icon">
                        <img src="assets/images/service-icon-03.png" alt="" />
                      </div>
                      <div className="right-text">
                        <h4>Occupant Lists</h4>
                        <p>
                          Occupant Lists consist of addresses of all occupants
                          in a given zip code(s) or other defined geographical
                          area.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="item wow fadeIn"
                      data-wow-duration="1s"
                      data-wow-delay="1.1s"
                    >
                      <div className="icon">
                        <img src="assets/images/service-icon-04.png" alt="" />
                      </div>
                      <div className="right-text">
                        <h4>Phone Connect Lists</h4>
                        <p>
                          The Phone Connect List consists of new land line phone
                          hookups.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="about" className="our-services section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 align-self-center  wow fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="left-image">
                <img src="assets/images/services-left-image.png" alt="" />
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <div className="section-heading">
                <h2>
                  Use our Mailing Lists to connect with the New Movers in your
                  area.
                </h2>
                <p>
                  When families or individuals move to a new area, they are in
                  need of everything from basic services like banking and
                  groceries to social engagement like organizations and
                  Churches. For over 20 years New Movers Evangelism has been
                  helping Churches reach out to these New Movers, and we have
                  been blessed with an astounding track record!
                </p>
              </div>
              <div className="section-heading">
                <h2>Why Choose Us</h2>
                <div className="checklist">
                  <div>
                    <em className="fa fa-check-square-o"></em>
                    &nbsp;Guaranteed 100% Deliverable
                  </div>
                  <div>
                    <em className="fa fa-check-square-o"></em>&nbsp;New names
                    every month
                  </div>
                  <div>
                    <em className="fa fa-check-square-o"></em>&nbsp;Only movers
                    in your selected areas
                  </div>
                  <div>
                    <em className="fa fa-check-square-o"></em>&nbsp;No minimum
                    order size
                  </div>
                  <div>
                    <em className="fa fa-check-square-o"></em>&nbsp;No minimum
                    subscription period
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonials section">
        <div className="container">
          <div className="row">
            <div className="col-2 col-lg-1 offset-lg-3">
              <button
                className="arrow"
                onClick={() =>
                  setTestimonialIndex(
                    (testimonialIndex + testimonials.length - 1) %
                      testimonials.length
                  )
                }
              >
                <i className="fa fa-angle-double-left"></i>
              </button>
            </div>
            <div className="col-8 col-lg-4">
              <h2>Testimonials</h2>
            </div>
            <div className="col-2 col-lg-1">
              <button
                className="arrow"
                onClick={() =>
                  setTestimonialIndex(
                    (testimonialIndex + 1) % testimonials.length
                  )
                }
              >
                <i className="fa fa-angle-double-right"></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="offset-lg-1 col-lg-10 col-sm-12">
              <div className="testimonial-card">
                {testimonialIndex % 2 ? (
                  <span>
                    <p>{currentTestimonial.description}</p>
                    <h4>{`- ${currentTestimonial.author} (${currentTestimonial.location})`}</h4>
                  </span>
                ) : (
                  <div>
                    <p>{currentTestimonial.description}</p>
                    <h4>{`- ${currentTestimonial.author} (${currentTestimonial.location})`}</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="contact" className="contact-us section">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 align-self-center wow fadeInLeft"
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <div className="section-heading">
                <h2>
                  Get a free quote to see how many new movers we can connect you
                  with in your area!
                </h2>
                <p>
                  Or fill out the detailed contact form and we'll get back to
                  you as soon as we can.
                </p>
                <div className="phone-info">
                  <h4>
                    Give us a call:{" "}
                    <span>
                      <i className="fa fa-phone"></i>{" "}
                      <a href="#">1-800-856-8614</a>
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 wow fadeInRight"
              data-wow-duration="0.5s"
              data-wow-delay="0.25s"
            >
              <form id="contact" action="" method="post">
                <div className="row">
                  <div className="col-lg-6">
                    <fieldset>
                      <input
                        type="name"
                        name="name"
                        id="name"
                        placeholder="Name"
                        autoComplete="on"
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-6">
                    <fieldset>
                      <input
                        type="surname"
                        name="surname"
                        id="surname"
                        placeholder="Surname"
                        autoComplete="on"
                        required
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <input
                        type="text"
                        name="email"
                        id="email"
                        pattern="[^ @]*@[^ @]*"
                        placeholder="Your Email"
                        required=""
                      />
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <textarea
                        name="message"
                        type="text"
                        className="form-control"
                        id="message"
                        placeholder="Message"
                        required=""
                      ></textarea>
                    </fieldset>
                  </div>
                  <div className="col-lg-12">
                    <fieldset>
                      <button
                        type="submit"
                        id="form-submit"
                        className="main-button "
                      >
                        Send Message
                      </button>
                    </fieldset>
                  </div>
                </div>
                <div className="contact-dec">
                  <img src="assets/images/contact-decoration.png" alt="" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.25s"
            >
              <p>
                © Copyright 2021 Space Dynamic Co. All Rights Reserved.
                <br />
                Design:{" "}
                <a rel="nofollow" href="https://templatemo.com">
                  TemplateMo
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
